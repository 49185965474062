import React from "react";
import { NavLink } from "react-router-dom";

import "./NavLinks.scss";

const NavLinks = (props) => {
  const { onClose } = props;
  const onNavClick = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <ul className="nav-links">
      <li className="para-s">
        <NavLink
          className="text-secondary"
          to="/platform"
          exact="true"
          onClick={onNavClick}
        >
          Platform
        </NavLink>
      </li>
      <li className="para-s">
        <NavLink
          className="text-secondary"
          to="/pricing"
          exact="true"
          onClick={onNavClick}
        >
          Plans & Pricing
        </NavLink>
      </li>
      <li className="para-s">
        <NavLink
          className="text-secondary"
          to="/about"
          exact="true"
          onClick={onNavClick}
        >
          About
        </NavLink>
      </li>
      {/* <li className="para-s">
        <NavLink
          className="text-secondary"
          to="/blogs"
          exact="true"
          onClick={onNavClick}
        >
          Blog
        </NavLink>
      </li> */}
      <li className="para-s">
        <NavLink
          className="text-secondary"
          to="/faq"
          exact="true"
          onClick={onNavClick}
        >
          FAQ
        </NavLink>
      </li>
      <li className="para-s">
        <NavLink
          className="text-secondary"
          to="/contact"
          exact="true"
          onClick={onNavClick}
        >
          Contact
        </NavLink>
      </li>
    </ul>
  );
};

export default NavLinks;
