import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import MainNavigation from "./components/ui-components/Navigation/MainNavigation";
import Footer from "./components/ui-components/Footer/Footer";
import ScrollToTop from "./components/ui-components/ScrollTop";

const Home = lazy(() => import("./pages/HomePage"));
const Platform = lazy(() => import("./pages/PlatformPage"));
const Pricing = lazy(() => import("./pages/Pricing"));
const Faq = lazy(() => import("./pages/FaqPage"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const AboutUs = lazy(() => import("./pages/AboutPage"));
const ContactUs = lazy(() => import("./pages/ContactPage"));
const Blogs = lazy(() => import("./pages/BlogPage"));
const BlogDetail = lazy(() => import("./pages/BlogDetailPage"));
const FormCRS = lazy(() => import("./pages/FormCRSPage"));
const FormADV = lazy(() => import("./pages/FormADVPage"));
const LegalRegulatory = lazy(() => import("./pages/LegalRegulatoryPage"));
const Documentation = lazy(() => import("./pages/DocumentationPage"));
const RiskDisclosures = lazy(() => import("./pages/RiskDisclosures"));
const TermsConditions = lazy(() => import("./pages/TermsConditionsPage"));
const AuthenticationPage = lazy(() => import("./pages/AuthenticationPage"));
const NotFound = lazy(() => import("./pages/Notfound"));

const App = () => (
  <div>
    <Router basename="/">
      <ScrollToTop>
        <PageWrapper>
          <MainNavigation />
          <Suspense>
            <main>
              <Routes>
                <Route exact path="/" element={<Home />}></Route>
                <Route path="/platform" element={<Platform />}></Route>
                <Route path="/pricing" element={<Pricing />}></Route>
                <Route path="/pricing" element={<Pricing />}></Route>
                <Route path="/faq" element={<Faq />}></Route>
                <Route
                  path="/privacy-policy"
                  element={<PrivacyPolicy />}
                ></Route>
                <Route path="/about" element={<AboutUs />}></Route>
                <Route path="/contact" element={<ContactUs />}></Route>
                <Route path="/blogs" element={<Blogs />}></Route>
                <Route path="/blogs/:id" element={<BlogDetail />}></Route>
                <Route path="/form-crs" element={<FormCRS />}></Route>
                <Route path="/form-adv" element={<FormADV />}></Route>
                <Route
                  path="/terms-and-conditions"
                  element={<TermsConditions />}
                ></Route>
                <Route
                  path="/risk-disclosures"
                  element={<RiskDisclosures />}
                ></Route>
                <Route
                  path="/documentation-taxes"
                  element={<Documentation />}
                ></Route>
                <Route
                  path="/legal-regulatory"
                  element={<LegalRegulatory />}
                ></Route>
                <Route
                  path="/authentication/:id"
                  element={<AuthenticationPage />}
                ></Route>
                <Route path="*" element={<NotFound />} />
              </Routes>
            </main>
            <Footer />
          </Suspense>
        </PageWrapper>
      </ScrollToTop>
    </Router>
  </div>
);

function PageWrapper({ children }) {
  const location = useLocation();

  React.useEffect(() => {
    document.body.className = "bodyScrollLock";
    // if (location.pathname === "/platform") {
    //   document.body.classList.add("secondary-header-blue");
    // }
    // if (location.pathname === "/about" || location.pathname === "/platform") {
    //   document.body.classList.add("secondary-header-green");
    // }
    if (
      location.pathname === "/pricing" ||
      location.pathname === "/contact" ||
      location.pathname === "/platform"
    ) {
      document.body.classList.add("secondary-header-lb");
    }
  }, [location]);

  return <>{children}</>;
}

export default App;
