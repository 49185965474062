import React from "react";

import "./IconSet.scss";

const IconSet = (props) => {
  return (
    <div
      className={`icon icon-wrap-${props.bg || "default"}`}
      onClick={props.onClick}
    >
      {props.iconName === "arrow-right" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <g clipPath="url(#clip0_7_781)">
            <path
              d="M3.75 12H20.25"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.5 5.25L20.25 12L13.5 18.75"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_7_781">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ) : null}
      {props.iconName === "bolt" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
        >
          <path
            d="M5.21094 13.5H12.1016L9.70625 22.5L20.2109 10.5H13.3203L15.7109 1.5L5.21094 13.5Z"
            fill="white"
          />
        </svg>
      ) : null}
      {props.iconName === "play" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M17 12.0002C17.0004 12.1545 16.9608 12.3063 16.8851 12.4408C16.8095 12.5754 16.7003 12.688 16.5682 12.7678L8.38182 17.7758C8.2438 17.8603 8.08572 17.9064 7.92391 17.9094C7.7621 17.9124 7.60242 17.8722 7.46136 17.7928C7.32165 17.7147 7.20527 17.6008 7.12418 17.4628C7.0431 17.3248 7.00023 17.1676 7 17.0076V6.99281C7.00023 6.83274 7.0431 6.67563 7.12418 6.53762C7.20527 6.39961 7.32165 6.2857 7.46136 6.20758C7.60242 6.12823 7.7621 6.08798 7.92391 6.09098C8.08572 6.09397 8.2438 6.14011 8.38182 6.22463L16.5682 11.2326C16.7003 11.3124 16.8095 11.425 16.8851 11.5595C16.9608 11.6941 17.0004 11.8459 17 12.0002Z"
            fill="#2A2828"
          />
        </svg>
      ) : null}
    </div>
  );
};

export default IconSet;
